import { getEnviromentType } from 'modules/environment/getEnviromentType';
import { EType } from 'modules/environment/enums/EEnviromentType';

export const getCookie = (name: string): string | undefined => {
    if (getEnviromentType() === EType.BACK_END) return undefined;

    const cookieValue = document.cookie
        .split('; ')
        .find(row => row.startsWith(`${name}=`));

    return cookieValue ? cookieValue.split('=')[1] : undefined;
};